import {useStaticQuery, graphql} from 'gatsby';
import styled from 'styled-components';
import React from 'react';
import {PageContent} from '../components/Layout';
import RichText from '../components/RichText';
import {useLocation} from '@reach/router';
import {PageContentContainer, Appear} from '../components/atoms';
import {devices} from '../styles/breakpoints';
import {NavigationContext} from '../services/navigation';
import {Helmet} from 'react-helmet';

const Container = styled(PageContent)`
  display: flex;
  text-align: justify;
`;

const AboutContainer = styled(PageContentContainer)`
  max-width: 1080px;
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
  flex: none;
  font-weight: 300;
  @media ${devices.tabletPortrait} {
    font-weight: 400;
    padding: 0;
    /* width: 80%; */
    align-items: center;
  }
`;

const About: React.FC = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  const {state} = useLocation();
  const {strapi, bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
        publicURL
      }
      strapi {
        about {
          description
        }
      }
    }
  `);
  const {description} = strapi.about;
  const ref = React.useRef();
  return (
    <Container ref={ref}>
      <Helmet
        title={`About | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: 'Page with some words about Stavros Markonis',
          },
          {
            name: 'keywords',
            content: 'about, Stavros Markonis, film composer',
          },
          {
            property: `og:title`,
            content: `About | Stavros Markonis`,
          },
          {
            property: `og:description`,
            content: 'Page with some words about Stavros Markonis',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${bisbikis?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `About | Stavros Markonis`,
          },
          {
            name: `twitter:description`,
            content: 'Page with some words about Stavros Markonis',
          },
        ]}
      />
      <Appear>
        <AboutContainer translation={(state as any)?.enableTranslationTransition}>
          <RichText source={description} />
        </AboutContainer>
      </Appear>
    </Container>
  );
}

export default About;
